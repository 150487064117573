import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

import mock from './product-data.js'

let data = {
  products: mock,
  currentProduct: 1,
  cart: [],
  addToCart(product){
    this.cart.push(product);
  },
  removeFromCart(index){
    this.cart.splice(index, 1);
  },
  clearCart(){
    this.cart = [];
  },
  setCurrentProduct(productID){
    this.currentProduct = productID;
  }
}

new Vue({
  router,
  data,
  render: h => h(App)
}).$mount('#app')
